import clsx from "clsx";
import { Field } from "formik";
import * as React from "react";

import ErrorMessage from "./ErrorMessage";

export interface TextFieldProps {
    className?: string;
    label: string;
    name: string;
    placeholder: string;
}

const TextField = (props: TextFieldProps) => {
    const { className, label, name, placeholder } = props;
    return (
        <div className={clsx("flex flex-col", className)}>
            <label className="text-sm font-bold mb-1" htmlFor={name}>{label}</label>
            <Field
                className="p-1 border-solid border-neutral-600 border rounded"
                id={name}
                name={name}
                placeholder={placeholder} />
            <ErrorMessage name={name} />
        </div>
    );
};

export default TextField;
