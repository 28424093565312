import { faCheckCircle, faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
    Formik,
    Form,
    FormikHelpers,
    FormikErrors
} from "formik";
import * as React from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";

import Button from "./Button";
import TextArea from "./forms/TextArea";
import TextField from "./forms/TextField";

const getEndpoint = () => {
    return document.location.origin + "/api/SubmitContactForm";
}

interface FormData {
    name: string;
    telephoneNumber?: string;
    emailAddress?: string;
    details: string;
    captchaResponse: string;
}

const schema = Yup.object().shape({
    name: Yup
        .string()
        .required("Please enter your name.")
        .max(250, ({max}) => (`The maximum number of characters is ${max}.`)),
    emailAddress: Yup
        .string()
        .required("Please enter your e-mail address.")
        .max(250, ({max}) => (`The maximum number of characters is ${max}.`)),
    details: Yup
        .string()
        .required("Please enter your enquiry details."),
});

const initialValues: FormData = {
    name: "",
    details: "",
    captchaResponse: "",
    telephoneNumber: "",
    emailAddress: "",
};

const ContactForm = () => {

    const [ formState, setFormState ] = React.useState<"idle" | "submitting" | "submitted" | "failed">("idle");

    const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = React.useCallback(async (values: FormData, helpers: FormikHelpers<FormData>) => {
        if (!executeRecaptcha) {
            throw Error("Captcha not ready.");
        }

        try {
            setFormState("submitting");
            const token = await executeRecaptcha("submitContactForm");
            values.captchaResponse = token;

            await axios.post(getEndpoint(), values);
            setFormState("submitted");
        } catch(error) {
            // a validation error? show the user
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    const details = error.response.data;
                    const errors = convertValidationErrors(details);
                    helpers.setErrors(errors);
                    setFormState("idle");
                    return;
                }
            }

            // something else went wrong? tell the user
            setFormState("failed");
        }
    }, [executeRecaptcha]);

    const renderForm = () => (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={schema}>
            <Form className="w-full p-2">
                <div>
                    <div className="flex flex-col mx-auto">
                        <TextField className="w-full md:w-80 lg:w-96" label="Name*" name="name" placeholder="Your name" />
                        <div className="md:flex mt-5">
                            <TextField
                                className="w-full md:w-80 lg:w-96 md:mr-2"
                                label="E-mail Address*"
                                name="emailAddress"
                                placeholder="you@yourcompany.com" />
                            <TextField
                                className="w-full md:w-80 lg:w-96 md:ml-2 mt-5 md:mt-0"
                                label="Telephone Number (optional)"
                                name="telephoneNumber"
                                placeholder="Telephone number" />
                        </div>
                        <TextArea
                            className="mt-5"
                            rows={6}
                            name="details"
                            label="Your enquiry details*"
                            placeholder="" />
                        <Button
                            disabled={formState !== "idle"}
                            type="submit"
                            className="mt-4 mx-auto"
                            size="sm">
                            { formState === "submitting" ? (
                                <>
                                    <FontAwesomeIcon icon={faCircleNotch} fixedWidth className="mr-1" spin />
                                    Sending...
                                </>)  :
                                "Submit" }
                        </Button>
                    </div>
                </div>
            </Form>
        </Formik>
    );

    const renderSubmitted = () => (
        <div className="text-center p-4">
            <FontAwesomeIcon icon={faCheckCircle} fixedWidth className="text-8xl text-emerald-600" />
            <h2 className="text-xl font-bold mt-2">Thanks for the message!</h2>
            <p className="mt-2">We will be in touch shortly and look forward to having a chat about your requirements.</p>
        </div>
    )

    return formState === "submitted" ? renderSubmitted() : renderForm();
};

function convertValidationErrors(serverErrors: Record<string, string[]>) {
    const errors: FormikErrors<FormData> = {};

    for (const key in serverErrors) {
        const camelCasedKey: keyof FormData = toCamelCase(key);
        errors[camelCasedKey] = serverErrors[key][0];
    }

    return errors;
};

function toCamelCase(key: string): keyof FormData {
    return (key ?
    key[0].toLowerCase() + key.substring(1, key.length) :
    "") as keyof FormData;
};

const WrappedContactForm = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdFfOkdAAAAAEWRSygxz1nd7O-VYEHmNETElLib">
            <ContactForm />
        </GoogleReCaptchaProvider>
    )
}

export default WrappedContactForm;
