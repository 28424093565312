import { ErrorMessage as FormikErrorMessage } from "formik";
import * as React from "react";

export interface ErrorMessageProps {
    name: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
    const { name } = props;

    return (
        <FormikErrorMessage name={name}>
            {(msg) => <span className="text-xs text-red-600">{msg}</span>}
        </FormikErrorMessage>
    );
};

export default ErrorMessage;
