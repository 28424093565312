import * as React from "react";
import { Helmet } from "react-helmet";

import ContactForm from "../components/ContactForm";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/PageHeader";

const Contact = () => {

    return (
        <Layout>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <PageHeader title="Contact Us">
                If you believe there is something Eborsoft can help you with, or if you just need
                some more information about us and what we do, please get in touch using the form below.
            </PageHeader>
            <section className="container mx-auto">
                <div className="flex flex-col md:flex-row">
                    <div className="w-full px-8 md:w-2/3">
                        <ContactForm />
                    </div>
                    <div className="w-full md:w-1/3">
                        <div className="p-4 mt-8 mx-2 md:m-5 rounded-lg border border-solid border-gray shadow text-center md:text-left" style={{boxShadow: "0 10px 18px rgba(0,0,0,.08)"}}>
                            <h2 className="text-xl font-bold">Contact Details</h2>

                            <h3 className="mt-3">Customer Support</h3>
                            <p><a href="mailto:support@eborsoft.com">support@eborsoft.com</a></p>

                            <h3 className="mt-3">General Enquiries</h3>
                            <p><a href="mailto:support@eborsoft.com">info@eborsoft.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Contact;
