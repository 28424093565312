import clsx from "clsx";
import { Field } from "formik";
import * as React from "react";

import ErrorMessage from "./ErrorMessage";

export interface TextAreaProps {
    className?: string;
    label: string;
    name: string;
    placeholder: string;
    rows?: number;
}

const TextField = (props: TextAreaProps) => {
    const { className, label, name, placeholder, rows = 8 } = props;
    return (
        <div className={clsx("flex flex-col", className)}>
            <label className="text-sm font-bold mb-1" htmlFor={name}>{label}</label>
            <Field
                as="textarea"
                rows={rows}
                className="p-1 border-solid border-neutral-600 border rounded"
                id={name}
                name={name}
                placeholder={placeholder} />
            <ErrorMessage name={name} />
        </div>
    );
};

export default TextField;
