import * as React from "react";

export interface PageHeaderProps {
    title: string;
}

const PageHeader = (props: React.PropsWithChildren<PageHeaderProps>) => {
    const { children, title } = props;

    return (
        <div className="bg-primary-default pt-4 pb-5">
            <div className="container px-8 mx-auto text-white">
                <h1 className="text-3xl lg:text-5xl font-semibold mx-auto text-center">{title}</h1>
                { children &&
                    <p className="text-xs md:text-sm mx-auto text-center mt-4 w-full lg:w-2/3">
                        {children}
                    </p>
                }
            </div>
        </div>
    );
};

export default PageHeader;
